.autocomplete-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  
  .autocomplete-wrapper > div {
    width: 100%;
  }
  
  .autocomplete-wrapper input {
    border: 1px solid #cecece;
    padding: 6px 28px;
    font-size: 13px;
    width: 100%;
  }
  
  .autocomplete-wrapper input:focus {
    border-color: #90b2f0;
    box-shadow: none;
    outline: none;
  }
  
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #90b2f0;
    border-top: none;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 5px 20px -5px #9d9d9d;
  }
  
  .autocomplete-wrapper .item  {
    color: #404040;
    display: block;
    cursor: pointer;
    font-size: 13px;
    padding: 9px;
  }
  
  .autocomplete-wrapper .item.selected-item {
    background-color: #6ba2c2;
    color: #FAFBFC;
  }
  
  .autocomplete-wrapper .item:hover {
    background-color: #6ba2c2;
    color: #FAFBFC;
  }

  .MyItem-selected-item {
    background-color: #bfe1f4;
  }